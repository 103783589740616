<template>
  <div v-if="meal" class="panel inline">
    <div class="panel-header">
      <p>Meal: <b>{{meal.name}}</b></p>
    </div>
    <div class="panel-body">
      <div class="sides">
        <div class="main">
          <div class="full-scroller in-panel">
            <AppForm :fields="fields" :data="meal" @update="onUpdate" :meta="{allMealStyles}" />
            <MealRecipeForm :recipes="meal && meal.recipes" @add="onAddRecipe" @delete="onDeleteRecipe" />
            <MealIngredientForm :ingredients="meal.ingredients" @add="onAddIngredient" @delete="onDeleteIngredient" @sort="onSortIngredient" />
          </div>
        </div>
        <div class="side">
          <div class="full-scroller in-panel">
            <div class="box" v-if="meal.id">
              <LocaleForm :fields="localeFields" :all-locales="allLocales" :locales="locales" :on-submit="setLocale" />
            </div>
            <div class="box" v-if="allMealIngredients && allMealIngredients.length">
              <NutritionCounter :ingredients="allMealIngredients" />
            </div>
            <div class="box">
              <div class="diagonal-wrap">
                <button class="large-button" @click="onSubmit">{{ meal.id?'Update Meal':'Create Meal' }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import mealFields from "@/lib/form-fields/meal";
import AppForm from "@/components/ui/AppForm";
import MealRecipeForm from "@/components/forms/MealRecipeForm";
import MealIngredientForm from "@/components/forms/MealIngredientForm";
import NutritionCounter from "@/components/ui/NutritionCounter";
import Api, {ApiCall, ApiRequest} from "@/lib/Api";
import LocaleForm from "@/components/forms/LocaleForm";
import {actionIf, delayedIfLoading} from "@/lib/Async";

const localeFields = [
  {name: "name", label:"Name", type:"text"},
  {name: "description", label:"Description", type:"textarea"},
]

export default {
  name: "Meal",
  components: {LocaleForm, AppForm, MealRecipeForm, MealIngredientForm, NutritionCounter },
  data() {
    return {
      fields : mealFields,
      localeFields,
      meal : null,
      locales : null,
      settingLocale : null
    }
  },

  async mounted() {
    await delayedIfLoading(!this.isLoggedIn, this.initData, this.setLoading, () => this.isLoggedIn, () => this.$router.push('/'))
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'allMeals', 'allLocales','allRecipes','allMealStyles']),
    allMealIngredients() {
      const ingredients = this.meal && this.meal.ingredients ? [...this.meal.ingredients] : []
      if (this.meal.recipes) {
        for (let recipe of this.meal.recipes) {
          if (recipe.recipe_id) {
            const rec = this.allRecipes.find(it => it.id === recipe.recipe_id);
            if (rec) {
              for (let ingredient of rec.ingredients) ingredients.push(ingredient)
            }
          }
        }
      }
      return ingredients
    }
  },

  methods: {
    ...mapActions(['getMeals','getRecipes','getMealStyles','setLoading','setSuccess','getLocales','updateMeal','createMeal']),

    async onSubmit() {
      await this.setLoading(true);
      if (this.meal.id) { //update
        await this.updateMeal([this.meal, this.meal.id])
      }
      else { // create new
        await this.createMeal(this.meal);
        if (this.meal.id) {
          await this.$router.push('/meal/'+this.meal.id)
          await this.initData()
          this.meal = {...this.meal }
        }
      }
      await this.setLoading(false);
      await this.setSuccess("Meal saved");
    },

    onUpdate(values) {
      this.meal = {...this.meal, ...values};
    },

    onAddRecipe(recipe_id, servings = 1) {
      servings *= 1
      console.log({recipe_id, servings})
      this.meal.recipes.push({id: recipe_id, recipe_id, servings})
    },

    onDeleteRecipe(recipe_id) {
      this.meal.recipes = this.meal.recipes.filter(it => (it.id && it.id !== recipe_id))
    },

    onAddIngredient(ingredient_id, serving_size_id, serving_amount, amount_g, is_dynamic) {
        this.meal.ingredients.push({id: ingredient_id, ingredient_id, serving_size_id, serving_amount, amount_g, is_dynamic, meal_id: this.meal.id})
    },

    onDeleteIngredient(id) {
      this.meal.ingredients = this.meal.ingredients.filter(it => (it.id && it.id !== id))
    },

    onSortIngredient(oldIndex, newIndex) {
      const ing = this.meal.ingredients.splice(oldIndex, 1);
      this.meal.ingredients.splice(newIndex, 0, ing[0]);

      for (let i = 0; i < this.meal.ingredients.length; i++) {
        this.meal.ingredients[i].sort = i+1;
      }
    },

    async initData() {
      await actionIf([
        [!this.allMealStyles.length, this.allMealStyles],
        [!this.allMeals.length, this.getMeals],
        [!this.allRecipes.length, this.getRecipes],
        [!this.allLocales.length, this.getLocales],
      ])

      this.meal = !isNaN(this.$route.params.id*1) ? this.allMeals.find(it => it.id === this.$route.params.id*1) :
          {name: "New Meal", ingredients:[], recipes: []}

      if (this.meal.id) await this.getMealLocales()
    },

    async getMealLocales() {
      this.locales = await ApiRequest(ApiCall(Api.GetMealLocales, this.meal.id))
    },

    async createMealLocale(meal_id, locale_id, name, description) {
      return await ApiRequest(ApiCall(Api.CreateMealLocale, { locale_id, name, description },  meal_id))
    },
    async updateMealLocale(meal_id, locale_id, name, description) {
      return await ApiRequest(ApiCall(Api.UpdateMealLocale, {name, description},  meal_id+'/'+locale_id))
    },

    hasLocale(locale) {
      return this.locales ? !!this.locales.find(it => it.locale_id === locale.id) : false
    },

    getLocale(locale) {
      return this.locales.find(it => it.locale_id === locale.id)
    },

    async setLocale({name, description = '', id = null}, locale_id) {
      if (id) {
        await this.updateMealLocale(this.meal.id, locale_id, name, description)
        this.locales = this.locales.map(it => it.locale_id === locale_id ? {...it, name, description} : it)
      }
      else {
        const res = await this.createMealLocale(this.meal.id, locale_id, name, description)
        this.locales.push({id: res.insertId, locale_id, name, description})
      }
      this.settingLocale = null
    }
  },




}
</script>
