<template>
  <div class="box">
    <div class="even wrap">
      Mode: {{mode}}
      <div v-if="data['weight'] && data['height']"> / BMI: {{bmi}}</div>
      <div v-if="mode === 'katch' && (!data['neckCircumference'] || !data['waistCircumference'])"> / Please enter neck and waist circumference</div>
    </div>
    <div class="even wrap">
        <div>
            <h3>Weight</h3>
            <div class="form-input">
              <input :value="data['weight']" type="number" @change="(v) => onInputChange('weight', v.target.value)" />
            </div>
        </div>
        <div>
          <h3>Height</h3>
          <div class="form-input">
            <input :value="data['height']" type="number" @change="(v) => onInputChange('height', v.target.value)" />
          </div>
        </div>
        <div>
          <h3>Age</h3>
          <div class="form-input">
            <input :value="data['age']" type="number" @change="(v) => onInputChange('age', v.target.value)" />
          </div>
        </div>
    </div>
    <div class="even wrap" v-if="mode === 'katch'">
      <div>
        <h3>Neck</h3>
        <div class="form-input">
          <input :value="data['neckCircumference']" type="number" @change="(v) => onInputChange('neckCircumference', v.target.value)" />
        </div>
      </div>
      <div>
        <h3>Waist</h3>
        <div class="form-input">
          <input :value="data['waistCircumference']" type="number" @change="(v) => onInputChange('waistCircumference', v.target.value)" />
        </div>
      </div>
    </div>
    <div class="even wrap">
        <div>
          <h3>Gender</h3>
          <div class="form-input">
            <v-select v-model="data['gender']" :options="genders" @input="(v) => onInputChange('gender', v.value)" />
          </div>
        </div>
        <div>
          <h3>Activity Level</h3>
          <div class="form-input">
            <input :value="data['activityLevel']" type="number" min="1" max="4" @change="(v) => onInputChange('activityLevel', v.target.value)" />
          </div>
        </div>
        <div>
          <h3>Goal</h3>
          <div class="form-input">
            <v-select v-model="data['goal']" :options="goals" label="name" @input="(v) => onInputChange('goal', v)" />
          </div>
        </div>
    </div>
    <div class="even">
      <div>
        <h3>BMR</h3>
        <p class="bold">{{bmr}}</p>
      </div>
      <div>
        <h3>TDEE</h3>
        <p class="bold">{{tdee}}</p>
      </div>
      <div v-if="reduction">
        <h3>Reduction</h3>
        <p class="bold">{{reduction}}</p>
      </div>
    </div>
    <div class="even" v-if="mode === 'katch'">
      <div>
        <h3>BF</h3>
        <p class="bold">{{bodyFat}}</p>
      </div>
      <div>
        <h3>LBM</h3>
        <p class="bold">{{lbm}}</p>
      </div>
    </div>
    <div class="even">
      <div v-if="reduction">
        <h3>Calories</h3>
        <p class="bold">{{finalCalories}}</p>
      </div>
      <div v-if="data['protein']">
        <h3>Protein</h3>
        <p class="bold">{{data['protein'].toFixed(2)}}</p>
        <small>{{data['protein'].toFixed(2)*4}}</small>
      </div>
      <div v-if="data['fat']">
        <h3>Fat</h3>
        <p class="bold">{{data['fat'].toFixed(2)}}</p>
        <small>{{data['fat'].toFixed(2)*9}}</small>
      </div>
    </div>
  </div>
</template>
<script>
import {BMRtoTDEE, statsToBMR} from "@/lib/bmi-calculator";

export default {
    props: ['onChange','data','goals'],
    data() {
        return {
          gender: 'female',
          weight: '',
          height: '',
          age: '',
          goal: '',
          activityLevel: '',
        }
    },
    computed : {
      genders() {
        return [
          {label: 'Male', value: 'male' },
          {label: 'Female', value: 'female' },
      ]},
      mode() {
        if (!this.data['weight'] || !this.data['height']) return 'mifflin'
        if (this.bmi >= 25) return 'katch'
        return 'mifflin'
      },
      bmi() {
        if (this.data['weight'] && this.data['height']) {
          let height = this.data['height'], weight = this.data['weight']
          if (height >= 3) height /= 100;
          return (weight / (height * height)).toFixed(2);
        }
        return '';
      },
      bodyFat() {
        if (this.data['weight'] && this.data['height'] && this.data['neckCircumference'] && this.data['waistCircumference']) {
          if (this.data['gender'] === 'male') {
            return (10.1 - (0.239*this.data['height'])+(0.8*this.data['waistCircumference']) - (0.5*this.data['neckCircumference'])).toFixed(2)
          }
          else {
            return (19.2 - (0.239*this.data['height'])+(0.8*this.data['waistCircumference']) - (0.5*this.data['neckCircumference'])).toFixed(2)
          }
        }
        return '';
      },
      lbm() {
        return parseFloat(this.data['weight']*((100 - this.bodyFat)/100)).toFixed(2);
      },
      bmr() {
        console.log("G",this.data['gender'])
        if (this.data['weight'] && this.data['height'] && this.data['age'])
          return statsToBMR(this.data['weight'], this.data['height'], this.data['age'], this.data['gender'] === 'female');
        return '';
      },
      tdee() {
        if (this.bmr && this.data['activityLevel']) {
          return Math.round(BMRtoTDEE(this.bmr, this.data['activityLevel']))
        }
        return '';
      },
      reduction() {
        if (this.data['goal'] && this.bmr && this.tdee) {
          const goal = this.data['goal']
          if (goal.reduction_rules) {
            for (let rule of goal.reduction_rules) {
                if (rule.minTdee && rule.minTdee > this.tdee) continue;
                if (rule.maxTdee && rule.maxTdee < this.tdee) continue;
                return rule.reduction;
            }
          }
        }
        return '';
      },
      finalCalories() {
        if (this.tdee && this.reduction) {
          const goal = this.data['goal']
          if (goal.min_calories && goal.min_calories > this.tdee - this.reduction) return goal.min_calories;
          return this.tdee - this.reduction;
        }
        return '';
      }
    },
    methods: {
      onInputChange(prop, value) {
          this.onChange(prop, value);
          setTimeout(() => {
            if (this.finalCalories) this.onChange('calories', this.finalCalories);
            else this.onChange('calories', '');
          },1)
      }
    },
}
</script>
